import Axios from "axios";
export default({
    state: {
      registrations: []
    },
    mutations: {
      SET_REGISTRATIONS(state, payload){
        state.registrations = payload;
      }
    },
    actions: {  
      getRegistrations() {
        return Axios.get(process.env.VUE_APP_API_URL+"/registration",
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      },
      assignStudent({ dispatch }, payload){
        return Axios.post(process.env.VUE_APP_API_URL+"/registration/add", payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            dispatch("getRegistrations");
            return res.data;
        })
      }
    },
    getters: {},
  });