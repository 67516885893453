import Axios from "axios";
export default({
    state: {
      courses: []
    },
    mutations: {
      SET_COURSES(state, payload){
        state.courses = payload;
      }
    },
    actions: {  
      saveCourse({ dispatch },payload) {
        return Axios.post(process.env.VUE_APP_API_URL+"/course/add", payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            dispatch("getCourses");
            return res.data;
        })
      },
      getCourses() {
        return Axios.get(process.env.VUE_APP_API_URL+"/course",
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      }
    },
    getters: {},
  });