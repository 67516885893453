import Axios from "axios";
export default({
    state: {
      users: []
    },
    mutations: {
      SET_USERS(state, payload){
        state.users = payload;
      }
    },
    actions: {  
      saveUser({dispatch}, payload) {
        return Axios.post(process.env.VUE_APP_API_URL+"/auth/add", payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            dispatch("getUsers");
            return res.data;
        })
      },
      getUsers({commit}) {
        return Axios.get(process.env.VUE_APP_API_URL+"/auth/users",
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            commit("SET_USERS", res.data);
            return res.data;
        })
      }
    },
    getters: {
      getUser: (state, payload) => {
        return state.users.filter(user => user.user_id === payload)
      }
    },
  });