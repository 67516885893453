import Axios from "axios";
export default({
    state: {
      withdrawls: []
    },
    mutations: {
      SET_WITHDRAWLS(state, payload){
        state.withdrawls = payload;
      }
    },
    actions: {  
      saveWithdrawl({ dispatch },payload) {
        return Axios.post(process.env.VUE_APP_API_URL+"/withdrawl/add", payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            dispatch("getWithdrawls");
            return res.data;
        })
      },
      updateWithdrawl({ dispatch },payload) {
        return Axios.put(process.env.VUE_APP_API_URL+"/withdrawl/update/"+payload.with_id, payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            dispatch("getWithdrawls");
            return res.data;
        })
      },
      getWithdrawls() {
        return Axios.get(process.env.VUE_APP_API_URL+"/withdrawl",
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      }
    },
    getters: {},
  });