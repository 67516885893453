import Axios from "axios";
export default({
    state: {
      payments: []
    },
    mutations: {
      SET_PAYMENTS(state, payload){
        state.payments = payload;
      }
    },
    actions: {  
      savePayment({dispatch}, payload) {
        return Axios.post(process.env.VUE_APP_API_URL+"/payment/add", payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
          dispatch("getPayments");
          return res.data;
        })
      },
      getPayments() {
        return Axios.get(process.env.VUE_APP_API_URL+"/payment",
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      }
    },
    getters: {},
  });